body {
  margin: 0;
  padding: 0;
  background: #000000;
  font-family: "Inter", sans-serif;
  color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: "Inter", sans-serif;
}

:root {
  --bg: #000000;
  --text-color: white;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100;
}

h1 {
  font-size: 50px;
  margin-bottom: 0;
}

h2 {
  margin-top: 10px;
}

.socials {
  margin-top: 15px;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.socials-social {
  display: flex;
}

.social-item {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.icon-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 7px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: #dadce1;
  width: 23px;
  height: 23px;
}

.contact {
  text-align: center;
  line-height: 31px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  height: 32px;
  width: 120px;
  border-radius: 12px;
  background-color: white;
  cursor: pointer;
  display: flex;
}

.contact > span {
  margin: auto;
}

.footer {
  position: absolute;
  bottom: 12px;
  font-size: 12px;
}

.particles {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

.ios-15 {
  --duration: 7s;
  --easing: linear;
  --c-color-1: rgba(255, 163, 26, 0.7);
  --c-color-2: #1a23ff;
  --c-color-3: #e21bda;
  --c-color-4: rgba(255, 232, 26, 0.7);
  --c-shadow: rgba(255, 223, 87, 0.5);
  --c-shadow-inset-top: rgba(248, 52, 255, 0.9);
  --c-shadow-inset-bottom: rgba(255, 215, 253, 0.8);
  --c-radial-inner: #8c0e97;
  --c-radial-outer: #cb61d4;
  --c-color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  outline: none;
  position: relative;
  cursor: pointer;
  border: none;
  display: table;
  border-radius: 24px;
  padding: 0;
  margin-top: 30px;
  width: 120px;
  text-align: center;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1;
  color: var(--c-color);
  background: radial-gradient(
    circle,
    var(--c-radial-inner),
    var(--c-radial-outer) 80%
  );
  box-shadow: 0 0 14px var(--c-shadow);
}
.ios-15:before {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 120px;
  border-radius: 24px;
  box-shadow: inset 0 3px 12px var(--c-shadow-inset-top),
    inset 0 -3px 4px var(--c-shadow-inset-bottom);
}
.ios-15 .wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  border-radius: 24px;
  min-width: 120px;
  min-height: 40px;
  padding: 12px 0;
}
.ios-15 .wrapper span {
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 120px;
}
.ios-15 .wrapper .circle {
  width: 120px;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: blur(var(--blur, 8px));
  background: var(--background, transparent);
  transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
  animation: var(--animation, none) var(--duration) var(--easing) infinite;
}
.ios-15 .wrapper .circle.circle-1,
.ios-15 .wrapper .circle.circle-9,
.ios-15 .wrapper .circle.circle-10 {
  --background: var(--c-color-4);
}
.ios-15 .wrapper .circle.circle-3,
.ios-15 .wrapper .circle.circle-4 {
  --background: var(--c-color-2);
  --blur: 14px;
}
.ios-15 .wrapper .circle.circle-5,
.ios-15 .wrapper .circle.circle-6 {
  --background: var(--c-color-3);
  --blur: 16px;
}
.ios-15 .wrapper .circle.circle-2,
.ios-15 .wrapper .circle.circle-7,
.ios-15 .wrapper .circle.circle-8,
.ios-15 .wrapper .circle.circle-11,
.ios-15 .wrapper .circle.circle-12 {
  --background: var(--c-color-1);
  --blur: 12px;
}
.ios-15 .wrapper .circle.circle-1 {
  --x: 0;
  --y: -40px;
  --animation: circle-1;
}
.ios-15 .wrapper .circle.circle-2 {
  --x: 92px;
  --y: 8px;
  --animation: circle-2;
}
.ios-15 .wrapper .circle.circle-3 {
  --x: -12px;
  --y: -12px;
  --animation: circle-3;
}
.ios-15 .wrapper .circle.circle-4 {
  --x: 80px;
  --y: -12px;
  --animation: circle-4;
}
.ios-15 .wrapper .circle.circle-5 {
  --x: 12px;
  --y: -4px;
  --animation: circle-5;
}
.ios-15 .wrapper .circle.circle-6 {
  --x: 56px;
  --y: 16px;
  --animation: circle-6;
}
.ios-15 .wrapper .circle.circle-7 {
  --x: 8px;
  --y: 28px;
  --animation: circle-7;
}
.ios-15 .wrapper .circle.circle-8 {
  --x: 28px;
  --y: -4px;
  --animation: circle-8;
}
.ios-15 .wrapper .circle.circle-9 {
  --x: 20px;
  --y: -12px;
  --animation: circle-9;
}
.ios-15 .wrapper .circle.circle-10 {
  --x: 64px;
  --y: 16px;
  --animation: circle-10;
}
.ios-15 .wrapper .circle.circle-11 {
  --x: 4px;
  --y: 4px;
  --animation: circle-11;
}
.ios-15 .wrapper .circle.circle-12 {
  --blur: 14px;
  --x: 52px;
  --y: 4px;
  --animation: circle-12;
}
@keyframes circle-1 {
  33% {
    transform: translate(0px, 16px) translateZ(0);
  }
  66% {
    transform: translate(12px, 64px) translateZ(0);
  }
}
@keyframes circle-2 {
  33% {
    transform: translate(80px, -10px) translateZ(0);
  }
  66% {
    transform: translate(72px, -48px) translateZ(0);
  }
}
@keyframes circle-3 {
  33% {
    transform: translate(20px, 12px) translateZ(0);
  }
  66% {
    transform: translate(12px, 4px) translateZ(0);
  }
}
@keyframes circle-4 {
  33% {
    transform: translate(76px, -12px) translateZ(0);
  }
  66% {
    transform: translate(112px, -8px) translateZ(0);
  }
}
@keyframes circle-5 {
  33% {
    transform: translate(84px, 28px) translateZ(0);
  }
  66% {
    transform: translate(40px, -32px) translateZ(0);
  }
}
@keyframes circle-6 {
  33% {
    transform: translate(28px, -16px) translateZ(0);
  }
  66% {
    transform: translate(76px, -56px) translateZ(0);
  }
}
@keyframes circle-7 {
  33% {
    transform: translate(8px, 28px) translateZ(0);
  }
  66% {
    transform: translate(20px, -60px) translateZ(0);
  }
}
@keyframes circle-8 {
  33% {
    transform: translate(32px, -4px) translateZ(0);
  }
  66% {
    transform: translate(56px, -20px) translateZ(0);
  }
}
@keyframes circle-9 {
  33% {
    transform: translate(20px, -12px) translateZ(0);
  }
  66% {
    transform: translate(80px, -8px) translateZ(0);
  }
}
@keyframes circle-10 {
  33% {
    transform: translate(68px, 20px) translateZ(0);
  }
  66% {
    transform: translate(100px, 28px) translateZ(0);
  }
}
@keyframes circle-11 {
  33% {
    transform: translate(4px, 4px) translateZ(0);
  }
  66% {
    transform: translate(68px, 20px) translateZ(0);
  }
}
@keyframes circle-12 {
  33% {
    transform: translate(56px, 0px) translateZ(0);
  }
  66% {
    transform: translate(60px, -32px) translateZ(0);
  }
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
* {
  box-sizing: inherit;
}
*:before,
*:after {
  box-sizing: inherit;
}
